const configuration = {
  // paste: "https://09l65zrmkb.execute-api.us-west-2.amazonaws.com/dev/paste",
  get: "https://pasty.brod.es/get/",
  paste: "https://pasty.brod.es/paste",
  // get: "http://localhost:3000/get/",
  // paste: "http://localhost:3000/paste",
  // get: "https://09l65zrmkb.execute-api.us-west-2.amazonaws.com/dev/get/",,
  server: "https://pasty.brod.es",
  //server: "http://localhost:3000",
  shortURL: "",
};

export default configuration;

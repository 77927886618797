// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3s4xCf2g3BRrGapU37KXFW {\n  font-family: \"Sans Mono\", \"Consolas\", \"Courier\", monospace;\n  overflow: hidden;\n  white-space: pre-wrap;       /* css-3 */\n  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */\n  white-space: -pre-wrap;      /* Opera 4-6 */\n  white-space: -o-pre-wrap;    /* Opera 7 */\n  word-wrap: break-word;       /* Internet Explorer 5.5+ */\n}\n\n._3s4xCf2g3BRrGapU37KXFW .IWlo7R-LITum2kk5UkGCl {\n  padding-left: 1em;\n  border-style: solid;\n  border-width: 0 0 0 1px;\n  border-color: #525252;\n  padding-right: 1em;\n}\n\n._3s4xCf2g3BRrGapU37KXFW ._1cwEZ9LAorsFxDY10E8EAT {\n  padding-right: 0.5em;\n  padding-left: 1em;\n  cursor: pointer;\n  width: 1%;\n\n  user-select: none; /* CSS3 (little to no support) */\n  -ms-user-select: none; /* IE 10+ */\n  -moz-user-select: none; /* Gecko (Firefox) */\n  -webkit-user-select: none; /* Webkit (Safari, Chrome) */\n}\n\n@keyframes _3yMRaO-BlWUMWwN79McPD_ {\n  0% {\n    filter: grayscale(0) opacity(1) saturate(0);\n  }\n  100% {\n    filter: grayscale(0.3) opacity(0.9) saturate(9);\n  }\n}\n\n._3s4xCf2g3BRrGapU37KXFW ._284PJ8hkrSUaHfXM2WmJ7K {\n  animation-name: _3yMRaO-BlWUMWwN79McPD_;\n  animation-duration: 0.25s;\n  animation-iteration-count: 1;\n  animation-direction: alternate;\n  animation-timing-function: ease-out;\n  animation-fill-mode: forwards;\n  animation-delay: 0s;\n}\n\n._3s4xCf2g3BRrGapU37KXFW tr:first-child > * {\n  padding-top: 1.25em !important;\n}\n\n._3s4xCf2g3BRrGapU37KXFW tr:last-child > * {\n  padding-bottom: 1.25em !important;\n}\n\n._3s4xCf2g3BRrGapU37KXFW > table {\n  border-spacing: 0;\n}\n\n._3s4xCf2g3BRrGapU37KXFW > table, ._3s4xCf2g3BRrGapU37KXFW > table > tbody, ._3s4xCf2g3BRrGapU37KXFW tr {\n  width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"viewcode": "_3s4xCf2g3BRrGapU37KXFW",
	"code": "IWlo7R-LITum2kk5UkGCl",
	"linenumber": "_1cwEZ9LAorsFxDY10E8EAT",
	"highlighted": "_284PJ8hkrSUaHfXM2WmJ7K",
	"highlight": "_3yMRaO-BlWUMWwN79McPD_"
};
module.exports = exports;

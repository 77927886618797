export const GET_PASTE_FROM_URL = "GET_PASTE_FROM_URL";
export const CLEAR_PASTE = "CLEAR_PASTE";
export const DECRYPT_PASTE = "DECRYPT_PASTE";
export const SET_DECRYPTED_PASTE = "SET_DECRYPTED_PASTE";
export const SET_PASTE_PROGRESS = "SET_PASTE_PROGRESS";

export const READ_SETTINGS = "READ_SETTINGS";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_AND_SAVE_SETTINGS = "SET_AND_SAVE_SETTINGS";
export const LOAD_THEME = "LOAD_THEME";
export const SET_THEME = "SET_THEME";

export const SET_HIGHLIGHTED_LINES = "SET_HIGHLIGHTED_LINES";
export const SET_INITIAL_HIGHLIGHTED_LINES = "SET_INITIAL_HIGHLIGHTED_LINES";
export const ADD_HIGHLIGHTED_LINE = "ADD_HIGHLIGHTED_LINE";
export const ADD_HIGHLIGHTED_LINES = "ADD_HIGHLIGHTED_LINES";

export const ENCRYPT_THEN_SUBMIT_PASTE = "ENCRYPT_THEN_SUBMIT_PASTE";
export const POST_PASTE_TO_URL = "POST_PASTE_TO_URL";
export const REDIRECT_TO_SUBMITTED_PASTE = "REDIRECT_TO_SUBMITTED_PASTE";

export const SET_GENERAL_ERROR = "SET_GENERAL_ERROR";
